import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { IsSSR } from '../../helpers/isSSR';

class About extends Component {
  constructor(props) {
    super(props);
    this.isSSR = IsSSR();
  }

  componentDidMount() {
    if (!this.isSSR) window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className='container'>
          <ul className='breadcrumbs'>
            <li className='breadcrumbs__item'>
              <Link to='/' className='breadcrumbs__url'>
                Home
              </Link>
            </li>
            <li className='breadcrumbs__item breadcrumbs__item--current'>About</li>
          </ul>
        </div>

        <div className='main-container container' id='main-container'>
          <div className='blog__content mb-72'>
            <h1 className='page-title'>About</h1>
            <img src={'/assets/img/content/about/about_bg.jpg'} className='page-featured-img' />
            <div className='row justify-content-center'>
              <div className='col-lg-8'>
                <div className='entry__article'>
                  <p>IVoox.id lahir dari tekad kami menghadirkan informasi yang berkualitas dan terpercaya sebagai sumber rujukan.</p>
                  <p>Di tengah-tengah rimba raya informasi digital, kami hadir untuk melahirkan berita tanpa prasangka dan gossip tanpa fakta.</p>
                  <p>Jurnalisme IVoox.id, sebagaimana namanya yang kami ambil dari frasa: vox populi, vox dei, menomorsatukan suara publik dan kebenaran.</p>
                  {/* <p>
                    Penanggung Jawab
                    <br />Moechamad Oemar Sidiq
                  </p> */}

                  <p>
                    Penanggung Jawab/Pemimpin Redaksi
                    <br />
                    Rana Akbari Fitriawan
                  </p>
                  <p>
                    Redaktur Pelaksana
                    <br />
                    Rivansyah Dunda
                  </p>
                  <p>
                    Redaktur Foto
                    <br />
                    Budiyanto
                  </p>
                  <p>
                    Reporter
                    <br />
                    Hairami, Akasyah, Roro Raden, Kemal Faruq, Luthfi Ardi, Harun Rasyid, Firdaus Harva, Fahrurrazi Assyar, Rinda Suherlina
                  </p>
                  <p>
                    Media Sosial
                    <br />
                    Zahwa Indira, Kinanti Pangestuti, Maulana Haitami, Aulia
                  </p>
                  <p>
                    Desain & Multimedia
                    <br />
                    Sabrina, Rifqi, Fajar
                  </p>
                  <p>
                    Redaksi iVooxid,
                    <br />
                    redaksi@ivoox.id
                  </p>
                  <p>
                    Iklan/Marketing
                    <br />
                    marketing@ivoox.id
                  </p>
                  {/* <p>
										PT Media Nusantara Sakti
										<br />
										The City Tower
										<br />
										Jl. MH Thamrin No.81, LT12/1N
										<br />
										Jakarta. 10310 Indonesia
									</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(About);
