import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';

import { imagesUrl, baseUrlFront } from '../../config';
import Api from '../../services/api';

import Toast from '../../components/Toast';
import PopularPosts from '../../components/PopularPosts';
import SocialMedia from '../../components/SocialMedia';

import { IsSSR } from '../../helpers/isSSR';
import { slugToString } from '../../helpers/string';
import { Helmet } from 'react-helmet'

class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasPostCategory: [],
      loadingPostCategory: true,
      datasTopTen: [],
      loadingTopTen: true,
      subscribeEmail: '',
      submittedSubscribe: false,
      entities: {
        data: [],
        pagination: {
          total_found: 1,
          limit: 5,
          current_page: 1,
          total_page: 1
        }
      },
      first_page: 1,
      current_page: 1,
      offset: 4,
      limit: 5
    };
    this.isSSR = IsSSR();

    this.onSubmitSubscribe = this.onSubmitSubscribe.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (!this.isSSR) window.scrollTo(0, 0);
    this.fetchEntities();
    this.fetchTopTen();
    let searchParams = new URLSearchParams(this.props.location.search);
    let current_page = searchParams.get('page') && !isNaN(searchParams.get('page')) ? searchParams.get('page') : 1;
    this.setState(
      // { current_page: this.state.entities.pagination.current_page },
      { current_page: current_page },
      () => {
        this.fetchEntities();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    let params = new URLSearchParams(this.props.location.search);
    let paramsPrev = new URLSearchParams(prevProps.location.search);
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.setState(
        // { current_page: this.state.entities.pagination.current_page },
        { current_page: 1 },
        () => {
          window.scrollTo(0, 0);
          this.fetchEntities();
        }
      );
      this.fetchTopTen();
    } else if (paramsPrev.get('page') !== params.get('page')) {
      this.setState(
        // { current_page: this.state.entities.pagination.current_page },
        { current_page: params.get('page') },
        () => {
          window.scrollTo(0, 0);
          this.fetchEntities();
        }
      );
    }
  }

  fetchEntities() {
    const { match } = this.props;
    var slug = match.params.slug;
    this.setState({ loadingPostCategory: true }, () => {
      const params = `page=${this.state.current_page}&limit=${this.state.entities.pagination.limit}&keyword=&type=&state_id=3&schedule=0&tag=${slug}`;
      const route = `/post/get_by_tag?${params}`;
      Api.get(route, null)
        .then((response) => {
          this.setState({ entities: response });
          this.setState({ datasPostCategory: response.data });
          this.setState({ loadingPostCategory: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loadingPostCategory: false });
        });
    });
  }

  changePage(pageNumber) {
    this.setState({ current_page: pageNumber }, () => {
      this.props.history.push(`/tag/${this.props.match.params.slug}?page=${pageNumber}`);
      // window.scrollTo(0, 0);
      // this.fetchEntities()
    });
  }

  pagesNumbers() {
    let from = this.state.entities.pagination.current_page - this.state.offset;
    if (from < 1) {
      from = 1;
    }
    let to = from + this.state.offset * 2;
    if (to >= this.state.entities.pagination.total_page) {
      to = this.state.entities.pagination.total_page;
    }
    let pagesArray = [];
    for (let page = from; page <= to; page++) {
      pagesArray.push(page);
    }
    return pagesArray;
  }

  pageList() {
    return this.pagesNumbers().map((page) => {
      return (
        <span
          key={page}
          className={`pagination__page ${page === this.state.entities.pagination.current_page ? 'pagination__page--current' : null}`}
          onClick={() => this.changePage(page)}
        >
          {page}
        </span>
      );
    });
  }

  onChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  onSubmitSubscribe(e) {
    e.preventDefault();
    this.setState({ submittedSubscribe: true });
    const { subscribeEmail } = this.state;
    if (subscribeEmail) {
      const route = `/subcriber/create`;
      let params = {};
      params.email = subscribeEmail;
      Api.post(route, params, null)
        .then((response) => {
          if (response.status && response.status === 'success') {
            Toast(response.message, 'success');
          }
        })
        .catch((error) => {
          if (error.status && error.status === 'failed') {
            Toast(error.message);
          } else {
            Toast('Error internet connection');
          }
        });
    }
  }

  fetchTopTen() {
    this.setState({ loadingTopTen: true }, () => {
      const params = `page=1&limit=4&keyword=&state_id=3&schedule=0`;
      const route = `/post/populer?${params}`;
      Api.get(route, null)
        .then((response) => {
          this.setState({ datasTopTen: response.data });
          this.setState({ loadingTopTen: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loadingTopTen: false });
        });
    });
  }

  render() {
    const { datasPostCategory, loadingPostCategory, datasTopTen, loadingTopTen, subscribeEmail, submittedSubscribe } = this.state;
    const { match } = this.props;

    return (
      <div>
        <Helmet>
          <title>{slugToString(match.params.slug)} | Ivoox Indonesia</title>
          <meta name='title' content={`${slugToString(match.params.slug)} | Ivoox Indonesia`} />
        </Helmet>
        
        <div className='main-container container-ivoox'>
          <ul className='breadcrumbs'>
            <li className='breadcrumbs__item'>
              <Link to='/' className='breadcrumbs__url'>
                Home
              </Link>
            </li>
            <li className='breadcrumbs__item breadcrumbs__item--current'>Tag</li>
          </ul>
        </div>

        <div className='main-container pt-40 container-ivoox' id='main-container'>
          <div className='row'>
            <div className='col-lg-8 blog__content mb-72'>
              <h1 className='page-title tt-capitalize'>{slugToString(match.params.slug)}</h1>
              <h2>#{match.params.slug}</h2>
              {loadingPostCategory ? (
                <div>Loading...</div>
              ) : (
                <div>
                  {datasPostCategory.length > 0 ? (
                    <div>
                      {datasPostCategory.map((postCategory, indexPostCategory) => (
                        <article className='entry card post-list' key={indexPostCategory}>
                          <div
                            className='entry__img-holder post-list__img-holder card__img-holder'
                            style={{
                              backgroundImage: postCategory.post.mediaable !== null ? `url(${`${postCategory.post.mediaable.media.url}`})` : ''
                            }}
                          >
                            <Link
                              to={{
                                pathname: `/${postCategory.post.url}`,
                                search: '?tag_from=ivoox-react'
                              }}
                              className='thumb-url'
                            />
                            <img
                              src={postCategory.post.mediaable !== null ? `${postCategory.post.mediaable.media.url}` : ''}
                              alt=''
                              className='entry__img d-none'
                            />
                            <Link
                              to={{
                                pathname: `/category/${match.params.slug}`
                              }}
                              className='entry__meta-category entry__meta-category--label entry__meta-category--align-in-corner entry__meta-category--label'
                            >
                              {slugToString(match.params.slug)}
                            </Link>
                          </div>
                          <div className='entry__body post-list__body card__body'>
                            <div className='entry__header'>
                              <h2 className='entry__title'>
                                <Link
                                  to={{
                                    pathname: `/${postCategory.post.url}`,
                                    search: '?tag_from=ivoox-react'
                                  }}
                                >
                                  {postCategory.post.title}
                                </Link>
                              </h2>
                              <ul className='entry__meta'>
                                <li className='entry__meta-author'>
                                  <span>by</span>
                                  <Link
                                    to={{
                                      pathname: `/authorPost/${postCategory.post.user ? postCategory.post.user.username : 'Unknown'}`
                                    }}
                                  >
                                    {` `} {postCategory.post.user ? postCategory.post.user.name : 'Unknown'}
                                  </Link>
                                </li>
                                <li className='entry__meta-date'>{moment(postCategory.post.createdAt).format('LLLL')}</li>
                              </ul>
                            </div>
                          </div>
                        </article>
                      ))}
                      {this.state.entities.data && this.state.entities.data.length > 0 && (
                        <nav className='pagination'>
                          <a
                            href='javascript:void(0);'
                            onClick={() =>
                              1 === this.state.entities.pagination.current_page ? null : this.changePage(this.state.entities.pagination.current_page - 1)
                            }
                            className='pagination__page pagination__icon pagination__page--next'
                          >
                            <i className='ui-arrow-left' />
                          </a>
                          {this.pageList()}
                          <a
                            href='javascript:void(0);'
                            onClick={() =>
                              this.state.entities.pagination.total_page === this.state.entities.pagination.current_page
                                ? null
                                : this.changePage(this.state.entities.pagination.current_page + 1)
                            }
                            className='pagination__page pagination__icon pagination__page--next'
                          >
                            <i className='ui-arrow-right' />
                          </a>
                        </nav>
                      )}
                    </div>
                  ) : (
                    <div>Empty</div>
                  )}
                </div>
              )}
            </div>
            <aside className='col-lg-4 sidebar sidebar--right'>
              <PopularPosts loading={loadingTopTen} data={datasTopTen} />
              {submittedSubscribe && !subscribeEmail && (
                <div
                  className='help-block'
                  style={{
                    backgroundColor: 'red',
                    padding: 10,
                    color: 'white'
                  }}
                >
                  Email is required
                </div>
              )}
              <aside className='widget widget_mc4wp_form_widget'>
                <h4 className='widget-title'>Newsletter</h4>
                <p className='newsletter__text'>
                  <i className='ui-email newsletter__icon' />
                  Subscribe for our daily news
                </p>
                <form className='mc4wp-form' name='formSubscribe' onSubmit={this.onSubmitSubscribe}>
                  <div className='mc4wp-form-fields'>
                    <div className='form-group'>
                      <input type='email' name='subscribeEmail' placeholder='Your email' value={subscribeEmail} onChange={this.onChange} />
                    </div>
                    <div className='form-group'>
                      <input type='submit' className='btn btn-lg btn-color' value='Sign Up' />
                    </div>
                  </div>
                </form>
              </aside>
              <SocialMedia />
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default Tags;
