import React from 'react';
import { Helmet } from 'react-helmet';

import { baseUrlFront } from '../config';

import { GenerateString } from './GenerateRandomString';

export default (props) => {
  return (
    <div>
      <Helmet>
        <title>{`${props.data.title ? `${props.data.title} | ` : ''}IVoox Indonesia`}</title>
        <meta name='title' content={`${props.data.title} | Ivoox Indonesia`} />
        <meta
          name='description'
          content={
            props.data.description
              ? props.data.description
              : props.data.content
                  .replace(/(<([^>]+)>)/gi, ' ')
                  .replace(/&nbsp;/g, ' ')
                  .slice(0, 280)
          }
        />
        <meta name='keywords' content={props.data.keyword} />

        {/* Open Graph Meta Tags */}
        <meta property='og:type' content='article' />
        <meta property='og:url' content={`${baseUrlFront}/${props.data.url}`} />
        <meta property='og:title' content={props.data.title} />
        <meta
          property='og:description'
          content={
            props.data.description
              ? props.data.description
              : props.data.content
                  .replace(/(<([^>]+)>)/gi, ' ')
                  .replace(/&nbsp;/g, ' ')
                  .slice(0, 280)
          }
        />
        <meta property='og:image' content={props.data.mediaable && props.data.mediaable.media ? props.data.mediaable.media.url : ''} />
        <meta property='article:published_time' content={props.data.createdAt} />
        <meta property='article:modified_time' content={props.data.updatedAt} />
        <meta property='article:author' content={props.data.user?.name || ''} />
        <meta property='article:section' content={props.data.categoryables?.[0]?.category?.name} />
        <meta property='article:tag' content={props.data.tagables?.[0]?.tag?.name} />

        {/* Twitter Meta Tags */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:url' content={`${baseUrlFront}/${props.data.url}`} />
        <meta name='twitter:title' content={props.data.title} />
        <meta
          name='twitter:description'
          content={
            props.data.description
              ? props.data.description
              : props.data.content
                  .replace(/(<([^>]+)>)/gi, ' ')
                  .replace(/&nbsp;/g, ' ')
                  .slice(0, 280)
          }
        />
        <meta
          name='twitter:image'
          content={
            props.data.mediaable && props.data.mediaable.media
              ? `${props.data.mediaable.media.url}${GenerateString + Math.random().toString(36).substring(1)}`
              : ''
          }
        />

        {/* Additional Meta Tags */}
        <meta name='google-site-verification' content='kKYqdt3i-S2IAoZg1k_uaU7j5VpSzUaet1Tgmmhg0Pw' />
        <link rel='canonical' href={`${baseUrlFront}/${props.data.url}`} />

        <meta name='author' content={props.data.user?.name || ''} />
        <meta name='publisher' content='IVoox Indonesia' />
        <meta name='category' content={props.data.categoryables?.[0]?.category?.name || ''} />

        <meta name='robots' content='index, follow' />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Article',
            headline: props.data.title,
            description:
              props.data.description ||
              props.data.content
                .replace(/(<([^>]+)>)/gi, ' ')
                .replace(/&nbsp;/g, ' ')
                .slice(0, 280),
            image: props.data.mediaable?.media?.url || '',
            author: {
              '@type': 'Person',
              name: props.data.user?.name || 'Unknown'
            },
            datePublished: props.data.createdAt,
            mainEntityOfPage: `${baseUrlFront}/${props.data.url}`,
            publisher: {
              '@type': 'Organization',
              name: 'IVoox Indonesia',
              logo: {
                '@type': 'ImageObject',
                url: `${baseUrlFront}/assets/img/logo.png`
              }
            }
          })}
        </script>
      </Helmet>
    </div>
  );
};
