import React, { Component } from 'react';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton
} from 'react-share';
import LoadingOverlay from 'react-loading-overlay';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';
import { Player } from 'video-react';
// import AudioPlayer from 'react-h5-audio-player';
import ReactAudioPlayer from 'react-audio-player';

import Reaptcha from 'reaptcha';
import { Helmet } from 'react-helmet';

import HeaderMeta from '../../components/HeaderMeta';
import Toast from '../../components/Toast';
import PopularPosts from '../../components/PopularPosts';
import SocialMedia from '../../components/SocialMedia';

import loadData from '../../helpers/loadData';

import { scriptsSticky, scriptsSlide, scriptsShowAdsense, scriptsSetupPdf } from '../../utils/scripts';

import { apiUrl, baseUrlFront, sitekeyReaptcha, keyToken } from '../../config';

import Api from '../../services/api';

import { getStorageItem, removeStorageItem } from '../../helpers/storage';
import { IsSSR } from '../../helpers/isSSR';
import $ from 'jquery';
import GoogleAd from '../../components/GoogleAd';

const items = [
  // {
  // 	src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
  // 	altText: 'Slide 1',
  // 	caption: 'Slide 1'
  // },
  // {
  // 	src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa20%20text%20%7B%20fill%3A%23444%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa20%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23666%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3203125%22%20y%3D%22218.3%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
  // 	altText: 'Slide 2',
  // 	caption: 'Slide 2'
  // },
  // {
  // 	src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
  // 	altText: 'Slide 3',
  // 	caption: 'Slide 3'
  // }
];
// const PlayerAudio = () => (
//   <AudioPlayer
//     autoPlay
//     src='https://ivooxs3.s3.amazonaws.com/media_public/1591959043916_upload_a1c253a09711a75806da77fb3d0f88c6.mp3'
//     onPlay={(e) => console.log('onPlay')}
//     // other props here
//   />
// );
class Articles extends Component {
  constructor(props) {
    super(props);

    if (props.staticContext && props.staticContext.data) {
      console.log('load meta on server');
      this.state = {
        isMetaLoad: true,
        dataDetail: props.staticContext.data,
        dataDetailUser: {},
        dataDetailState: {},
        dataDetailCategoryables: [],
        dataDetailTagables: [],
        dataDetailMediaable: {},
        dataDetailMediaableMedia: {},
        loadingDetail: false,
        datasTopTen: [],
        loadingTopTen: true,
        datasComments: [],
        loadingComments: false,
        comment: '',
        commentName: '',
        commentEmail: '',
        commentWebsite: '',
        submittedComment: false,
        subscribeEmail: '',
        submittedSubscribe: false,
        errorLike: false,
        messageErrorLike: '',
        datasRelated: [],
        loadingRelated: false,
        activeIndex: 0,
        datasTypeImage: [],
        verified: false,
        loadingAdv: false,
        datasAdv: []
      };
    } else {
      console.log('load meta on client');
      this.state = {
        isMetaLoad: false,
        dataDetail: [],
        dataDetailUser: {},
        dataDetailState: {},
        dataDetailCategoryables: [],
        dataDetailTagables: [],
        dataDetailMediaable: {},
        dataDetailMediaableMedia: {},
        loadingDetail: true,
        datasTopTen: [],
        loadingTopTen: true,
        datasComments: [],
        loadingComments: false,
        comment: '',
        commentName: '',
        commentEmail: '',
        commentWebsite: '',
        submittedComment: false,
        subscribeEmail: '',
        submittedSubscribe: false,
        errorLike: false,
        messageErrorLike: '',
        datasRelated: [],
        loadingRelated: false,
        activeIndex: 0,
        datasTypeImage: [],
        verified: false
      };
    }

    this.isSSR = IsSSR();
    this.token = null;
    if (!this.isSSR) this.token = getStorageItem(keyToken);

    this.onSubmitComment = this.onSubmitComment.bind(this);
    this.onSubmitSubscribe = this.onSubmitSubscribe.bind(this);
    this.onChange = this.onChange.bind(this);

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  componentDidMount() {
    this.fetchAdv();
    setTimeout(() => {
      // if (window.__ROUTE_DATA__) {
      if (false) {
        this.setState({
          dataDetail: window.__ROUTE_DATA__
        });
        this.setState({
          dataDetailUser: this.state.dataDetail.data ? this.state.dataDetail.data.user : {}
        });
        this.setState({
          dataDetailState: this.state.dataDetail.data ? this.state.dataDetail.data.state : {}
        });
        this.setState({
          dataDetailCategoryables: this.state.dataDetail.data ? this.state.dataDetail.data.categoryables : []
        });
        this.setState({
          dataDetailTagables: this.state.dataDetail.data ? this.state.dataDetail.data.tagables : []
        });
        this.setState({
          dataDetailMediaable: this.state.dataDetail.data ? this.state.dataDetail.data.mediaable : {}
        });
        this.setState({
          dataDetailMediaableMedia:
            this.state.dataDetail.data && this.state.dataDetail.data.mediaable && this.state.dataDetail.data.mediaable.media !== null
              ? this.state.dataDetail.data.mediaable.media
              : null
        });
        if (this.state.dataDetail.data && this.state.dataDetail.data?.type === 'image') {
          var lists = [];
          var no = 1;
          if (this.state.dataDetail.data.mediaables.length > 0) {
            this.state.dataDetail.data.mediaables.map((dt) => {
              var item = {};
              item.src = dt.media.url;
              item.url = dt.media.url;
              item.altText = no;
              // item.caption = dt.media.caption ? dt.media.caption : this.state.dataDetail.data.title;
              item.caption = '';
              // item.header = 'Gambar No ' + no + '/' + (this.state.dataDetail.data.mediaables.length);
              item.header = '';
              no += 1;
              lists.push(item);
            });
          }
          this.setState({ datasTypeImage: lists });
        }
        this.setState({ loadingDetail: false });
        this.fetchComments();
        this.fetchTopTen();
        delete window.__ROUTE_DATA__;
      } else {
        this.fetchDetail();
      }
    }, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.fetchDetail();
    }
  }

  bundleInternalScriptsSlide() {
    scriptsSlide(() => {
      console.log('[scriptsSlide.js] in Home');
    });
  }

  bundleInternal() {
    scriptsSticky(() => {
      console.log('[scriptSticky.js]');
    });
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.state.datasTypeImage.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.state.datasTypeImage.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  onChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  onSubmitComment(e) {
    e.preventDefault();
    this.setState({ submittedComment: true });
    const { comment } = this.state;
    if (comment) this.createComments();
  }

  onSubmitSubscribe(e) {
    e.preventDefault();
    this.setState({ submittedSubscribe: true });
    const { subscribeEmail } = this.state;
    if (subscribeEmail) {
      const route = `/subcriber/create`;
      let params = {};
      params.email = subscribeEmail;
      Api.post(route, params, null)
        .then((response) => {
          if (response.status && response.status === 'success') {
            Toast(response.message, 'success');
          }
        })
        .catch((error) => {
          if (error.status && error.status === 'failed') {
            Toast(error.message);
          } else {
            Toast('Error internet connection');
          }
        });
    }
  }

  fetchAdv() {
    this.setState({ loadingAdv: true }, () => {
      const params = `page=1&limit=20&keyword=`;
      const route = `/ad/index?${params}`;
      Api.get(route, null)
        .then((response) => {
          var lists = {};
          if (response.data.length > 0) {
            response.data.map((item) => {
              lists[item.position] = item;
            });
          }
          this.setState({ datasAdv: lists });
          this.setState({ loadingAdv: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loadingAdv: false });
        });
    });
  }

  fetchTopTen() {
    const { dataDetail } = this.state;
    this.setState({ loadingTopTen: true }, () => {
      const params = `page=1&limit=4&keyword=&state_id=3&schedule=0&not_id=${dataDetail.data.id}`;
      const route = `/post/populer?${params}`;
      Api.get(route, null)
        .then((response) => {
          this.setState({ datasTopTen: response.data });
          this.setState({ loadingTopTen: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loadingTopTen: false });
        });
    });
  }

  fetchDetail() {
    window.scrollTo(0, 0);
    const { match } = this.props;
    const slug = match.params.slug;
    loadData('news', slug, this.token).then((data) => {
      if (data.status === 'failed') {
        this.props.history.push({
          pathname: `/notFound`
        });
      } else {
        this.setState({
          dataDetail: data
        });
        this.setState({
          dataDetailUser: this.state.dataDetail.data ? this.state.dataDetail.data.user : {}
        });
        this.setState({
          dataDetailState: this.state.dataDetail.data ? this.state.dataDetail.data.state : {}
        });
        this.setState({
          dataDetailCategoryables: this.state.dataDetail.data ? this.state.dataDetail.data.categoryables : []
        });
        this.setState({
          dataDetailTagables: this.state.dataDetail.data ? this.state.dataDetail.data.tagables : []
        });
        this.setState({
          dataDetailMediaable: this.state.dataDetail.data ? this.state.dataDetail.data.mediaable : {}
        });
        this.setState({
          dataDetailMediaableMedia:
            this.state.dataDetail.data && this.state.dataDetail.data.mediaable && this.state.dataDetail.data.mediaable.media !== null
              ? this.state.dataDetail.data.mediaable.media
              : null
        });
        if (this.state.dataDetail.data && this.state.dataDetail.data?.type === 'image') {
          var lists = [];
          var no = 1;
          if (this.state.dataDetail.data.mediaables.length > 0) {
            this.state.dataDetail.data.mediaables.map((dt) => {
              var item = {};
              item.src = dt.media.url;
              item.url = dt.media.url;
              item.altText = no;
              item.caption = dt.media.caption ? dt.media.caption : this.state.dataDetail?.data?.title;
              // item.caption = '';
              // item.header = 'Gambar No ' + no + '/' + (this.state.dataDetail.data.mediaables.length);
              item.header = '';
              no += 1;
              lists.push(item);
            });
          }
          this.setState({ datasTypeImage: lists });
        }
        this.setState({ loadingDetail: false });
        if (this.state.dataDetail.data?.type === 'ebook') {
          // this.setupPdf(this.state.dataDetail.data.ebook.media.url);
          setTimeout(() => {
            scriptsSetupPdf(() => {
              console.log('[scriptsSetupPdf.js]');
            });
          }, 600);
        }
        setTimeout(() => {
          scriptsShowAdsense(() => {
            console.log('[scriptsShowAdsense.js]');
          });
        }, 600);
        this.fetchTopTen();
        this.fetchComments();
        this.fetchRelated();
        this.bundleInternal();
      }
    });
  }

  fetchRelated() {
    const { dataDetail } = this.state;
    let searchParams = new URLSearchParams(this.props.location.search);
    searchParams = dataDetail.data.tagables && dataDetail.data.tagables[0] && dataDetail.data.tagables[0].tag ? dataDetail.data.tagables[0].tag.slug : '';
    this.setState({ loadingRelated: true }, () => {
      const params = `page=1&limit=10&keyword=&type=&state_id=3&schedule=0&tag=${searchParams}&not_id=${dataDetail.data.id}`;
      const route = `/post/get_by_tag?${params}`;
      Api.get(route, null)
        .then((response) => {
          this.setState({ datasRelated: response.data });
          this.setState({ loadingRelated: false });
          setTimeout(() => {
            this.bundleInternalScriptsSlide();
          }, 900);
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loadingRelated: false });
        });
    });
  }

  fetchComments() {
    this.setState({ loadingComments: false }, () => {
      const data = {
        commentable_type: 'App/Post',
        commentable_id: this.state.dataDetail.data.id
      };
      fetch(`${apiUrl}/comment/getComment?page=1&limit=1000&keyword=`, {
        method: 'POST',
        headers: [['Content-Type', 'application/json;text/plain']],
        body: JSON.stringify(data)
      })
        .then(function (response) {
          return response.json();
        })
        .then((response) => {
          this.setState({ datasComments: response.data });
          this.setState({ loadingComments: false });
        });
    });
  }

  createComments() {
    if (this.token) {
      const { comment } = this.state;
      this.setState({ loadingCreateComments: false }, () => {
        const data = {
          commentable_type: 'App/Post',
          commentable_id: this.state.dataDetail.data.id,
          content: `${comment}`
        };
        fetch(`${apiUrl}/comment/create`, {
          method: 'POST',
          headers: [
            ['Content-Type', 'application/json;text/plain'],
            ['x-access-token', this.token]
          ],
          body: JSON.stringify(data)
        })
          .then(function (response) {
            return response.json();
          })
          .then((response) => {
            if (response.status === 'success') Toast('The comment was sent successfully', 'success');
            else this.checkError(response);
            this.fetchComments();
            this.fetchDetail();
            this.setState({ submittedComment: false });
            this.setState({ loadingCreateComments: false });
            this.setState({ comment: '' });
          });
      });
    } else {
      Toast('You need to login first before comment!');
      this.props.history.push(`/auth?page=article`);
    }
  }

  renderTagables() {
    const { dataDetailTagables } = this.state;
    if (dataDetailTagables.length > 0)
      return dataDetailTagables.map((tagables) => {
        return (
          <Link key={tagables.tag.id} to={`/tag/${tagables.tag.slug}`} rel='tag'>
            {tagables.tag.name}
          </Link>
        );
      });
    else return null;
  }

  renderCategoryables() {
    const { dataDetailCategoryables } = this.state;
    if (dataDetailCategoryables.length > 0)
      return dataDetailCategoryables.map((categoryables) => {
        return (
          <Link
            key={categoryables.category.id}
            to={`/category/${categoryables.category.slug}`}
            rel='category'
            className='entry__meta-category entry__meta-category--label entry__meta-category--label'
            style={{ marginRight: 10 }}
          >
            {categoryables.category.name}
          </Link>
        );
      });
    else return null;
  }

  submitLike(typeLike) {
    // if (this.token) {
    this.setState({ loadingLike: false }, () => {
      const data = {};
      var route = 'like/create';
      var method = 'POST';
      if (typeLike === 'like') {
        data.likeable_type = 'App/Post';
        data.likeable_id = this.state.dataDetail.data.id;
      } else {
        route = 'like/dislike';
        data.likeable_type = 'App/Post';
        data.likeable_id = this.state.dataDetail.data.id;
      }
      fetch(`${apiUrl}/${route}`, {
        method: 'POST',
        headers: [
          ['Content-Type', 'application/json;text/plain'],
          ['x-access-token', this.token]
        ],
        body: JSON.stringify(data)
      })
        .then(function (response) {
          return response.json();
        })
        .then((response) => {
          if (response.status === 'failed') {
            this.checkError(response);
            this.setState({ errorLike: true });
          } else {
            Toast(`Successfully ${typeLike === 'like' ? 'Like' : 'Unlike'}`, 'success');
            this.setState({ messageErrorLike: '' });
            this.setState({ errorLike: false });
            this.fetchDetail();
          }
          this.setState({ loadingLike: false });
        });
    });
    // } else {
    //   Toast('You need to login first before like!');
    //   this.props.history.push(`/auth?page=article`);
    // }
  }

  downloadEbook() {
    if (this.token) {
      this.setState({ loadingLike: false }, () => {
        const data = {};
        var route = 'post/download';

        fetch(`${apiUrl}/${route}/${this.state.dataDetail.data.url}`, {
          method: 'GET',
          headers: [
            ['Content-Type', 'application/json;text/plain'],
            ['x-access-token', this.token]
          ]
        })
          .then(function (response) {
            return response.json();
          })
          .then((response) => {
            if (response.status === 'failed') {
              this.checkError(response);
            } else {
              // const url = window.URL.createObjectURL(new Blob([response.data.ebook]));
              // window.open(response.data.ebook,true);
              const link = document.createElement('a');
              // var att = document.createAttribute("download")
              link.setAttribute('download', 'download.pdf');
              link.setAttribute('target', '_blank');
              link.setAttribute('rel', 'noopener noreferrer');
              link.href = response.data.ebook;
              document.body.appendChild(link);
              //link.click();
              document.body.removeChild(link);
            }
            this.setState({ loadingLike: false });
          });
      });
    } else {
      Toast('You need to login first before like!');
      this.props.history.push(`/auth?page=article`);
    }
  }

  checkError(response) {
    if (response.status && response.status === 'failed') {
      if (response.error) {
        this.setState({ errors: response.error });
        Toast(response.message);
        if (response.message == 'Fail to Authentication.') this.props.history.push(`/auth`);
      } else {
        Toast(response.message);
      }
    } else {
      Toast('Error internet connection');
    }
  }

  setupPdf(url) {}

  onVerify = (recaptchaResponse) => {
    this.setState({
      verified: true
    });
  };

  render() {
    let {
      dataDetail,
      isMetaLoad,
      dataDetailUser,
      dataDetailTagables,
      dataDetailMediaable,
      dataDetailMediaableMedia,
      loadingDetail,
      datasTopTen,
      loadingTopTen,
      comment,
      commentName,
      commentEmail,
      commentWebsite,
      submittedComment,
      subscribeEmail,
      submittedSubscribe,
      datasComments,
      loadingComments,
      loadingCreateComments,
      messageErrorLike,
      errorLike,
      datasRelated,
      loadingRelated,
      activeIndex,
      datasTypeImage,
      loadingAdv,
      datasAdv
    } = this.state;

    var mediaBanner = null;
    if (dataDetailMediaableMedia) mediaBanner = dataDetailMediaableMedia.url;
    let searchParams = new URLSearchParams(this.props.location.search);

    const slides = datasTypeImage.map((item) => {
      return (
        <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item.src}>
          <div
            className='containerImg'
            style={{
              marginBottom: 16,
              backgroundImage: item && item.src ? `url(${item.src})` : 'url(/static/media/logo-dark-beta.f32370e6.png)',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center'
            }}
          >
            {/* <img
							src={item.src}
							alt={item.altText}
							style={{ width: '100%' }}
							onError={e => {
								e.target.src =
									'https://tpc.googlesyndication.com/simgad/4354010893698296582?w=400&h=209'
							}}
						/> */}
            <div className='contentImg'>
              <h2
                style={{
                  color: 'white',
                  margin: 0,
                  padding: 0
                }}
              >
                {item.header}
              </h2>
              {/* <p style={{ color: 'whitesmoke' }}>
                {item.caption.length > 50
                  ? `${item.caption.substring(0, 50)}...`
                  : `${item.caption}`}
              </p> */}
            </div>
          </div>
        </CarouselItem>
      );
    });

    if (typeof window === 'undefined') {
      global.window = {};
    }

    if (!(dataDetail && dataDetail.data)) return null;

    if (!loadingDetail) dataDetail = dataDetail.data;

    return (
      <div className='main-container container-ivoox' id='main-container'>
        {isMetaLoad && <HeaderMeta data={dataDetail} />}
        {!isMetaLoad && !loadingDetail && (
          <Helmet>
            <title>{`${dataDetail.title} | IVoox Indonesia s`}</title>
            <meta name='title' content={`${dataDetail.title} | Ivoox Indonesia`} />
            <meta
              name='description'
              content={
                dataDetail.description
                  ? dataDetail.description
                  : dataDetail.content
                      .replace(/(<([^>]+)>)/gi, ' ')
                      .replace(/&nbsp;/g, ' ')
                      .slice(0, 280)
              }
            />
            <meta name='keywords' content={dataDetail.keyword} />
            <meta property='og:title' content={`${dataDetail.title} | Ivoox Indonesia`} />
            <meta
              property='og:description'
              content={
                dataDetail.description
                  ? dataDetail.description
                  : dataDetail.content
                      .replace(/(<([^>]+)>)/gi, ' ')
                      .replace(/&nbsp;/g, ' ')
                      .slice(0, 280)
              }
            />
            <meta property='og:image' content={mediaBanner} />
            <meta property='og:url' content={`${baseUrlFront}/${dataDetail.url}`} />
            <meta property='og:type' content='article' />
            <meta property='article:published_time' content={dataDetail.createdAt} />

            <meta property='article:author' content={dataDetail.user?.name || ''} />
            <meta property='article:section' content={dataDetail.categoryables?.[0]?.category?.name} />
            <meta property='article:tag' content={dataDetail.tagables?.[0]?.tag?.name} />
            <link rel='canonical' href={`${baseUrlFront}/${dataDetail.url}`} />

            <meta name='author' content={dataDetail.user?.name || ''} />
            <meta name='publisher' content='IVoox Indonesia' />
            <meta name='category' content={dataDetail.categoryables?.[0]?.category?.name || ''} />

            <meta name='robots' content='index, follow' />
            <script type='application/ld+json'>
              {JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'Article',
                headline: dataDetail.title,
                description:
                  dataDetail.description ||
                  dataDetail.content
                    .replace(/(<([^>]+)>)/gi, ' ')
                    .replace(/&nbsp;/g, ' ')
                    .slice(0, 280),
                image: dataDetail.mediaable?.media?.url || '',
                author: {
                  '@type': 'Person',
                  name: dataDetail.user?.name || 'Unknown'
                },
                datePublished: dataDetail.createdAt,
                mainEntityOfPage: `${baseUrlFront}/${dataDetail.url}`,
                publisher: {
                  '@type': 'Organization',
                  name: 'IVoox Indonesia',
                  logo: {
                    '@type': 'ImageObject',
                    url: `${baseUrlFront}/assets/img/logo.png`
                  }
                }
              })}
            </script>
          </Helmet>
        )}
        {!loadingDetail && (
          <div className='row'>
            <div className='col-lg-8 blog__content mb-72'>
              <div
                className='content-box'
                style={{
                  padding: 0,
                  marginTop: window.outerWidth < 480 ? 10 : 0
                }}
              >
                <article className='entry mb-0'>
                  <div className='single-post__entry-header entry__header'>
                    {this.renderCategoryables()}
                    <div style={{ marginTop: 16 }}>
                      <p>
                        <i>{dataDetail.upperdeck}</i>
                      </p>
                    </div>
                    <h1 className='single-post__entry-title' dangerouslySetInnerHTML={{ __html: dataDetail.title }}></h1>
                    <div style={{ marginTop: 16 }}>
                      <p>
                        <i>{dataDetail.lowerdeck}</i>
                      </p>
                    </div>
                    <div className='entry__meta-holder'>
                      <ul className='entry__meta'>
                        <li className='entry__meta-author'>
                          <span>by</span>
                          <Link
                            to={{
                              pathname: `/authorPost/${dataDetailUser.username}`
                            }}
                          >
                            {` `} {dataDetailUser.name}
                          </Link>
                        </li>
                        <li className='entry__meta-date'>{moment(dataDetail.createdAt).format('LLLL')}</li>
                      </ul>
                      <ul className='entry__meta'>
                        {/* <li className="entry__meta-views">
													<i className="ui-eye"></i>
													<span>{dataDetail.total_view}</span>
												</li> */}
                        {/* <li className="entry__meta-comments">
													<a href="#">
														<i className="ui-chat-empty"></i> {dataDetail.total_comment}
													</a>
												</li> */}
                      </ul>
                    </div>
                  </div>
                  <div className='entry__img-holder'>
                    {dataDetail.type !== 'image' && dataDetail.type !== 'ebook' && (
                      <img src={mediaBanner !== null ? `${mediaBanner}` : ''} alt='' className='entry__img' />
                    )}
                    {dataDetail.type !== 'image' && dataDetail.type !== 'ebook' && dataDetailMediaableMedia && dataDetailMediaableMedia.caption && (
                      <div className='caption-foto-article'>{dataDetailMediaableMedia.caption}</div>
                    )}
                    {dataDetail.type === 'ebook' && (
                      <div>
                        <div id='flipbook' data-url={dataDetail.ebook.media.url} data-pdf-disable-range-requests='false'></div>
                        <input type='hidden' id='flipbook-url' value={dataDetail.ebook.media.url} />
                        <div className='row'>
                          <div className='col-md-8 col-lg-8' style={{ marginTop: 10 }}>
                            {dataDetail.audio && dataDetail.audio_status === 1 && <ReactAudioPlayer src={dataDetail.audio.media.url} controls />}
                          </div>
                        </div>
                        <hr style={{ marginTop: '10px', marginBottom: '10px' }}></hr>
                        <div className='row'>
                          <div className='col-md-4' style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <ul class=''>
                              <li class='ebook-meta-title'>
                                <span>Author</span>
                              </li>
                              <li class='ebook-meta-desc'>{dataDetail.writer}</li>
                            </ul>
                          </div>
                          <div className='col-md-4' style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <ul class=''>
                              <li class='ebook-meta-title'>
                                <span>Published Date</span>
                              </li>
                              <li class='ebook-meta-desc'>{moment(dataDetail.published_date).format('LL')}</li>
                            </ul>
                          </div>
                          <div className='col-md-4' style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <ul class=''>
                              <li class='ebook-meta-title'>
                                <span>Download</span>
                              </li>
                              <li class='ebook-meta-desc'>{dataDetail.download_count}</li>
                            </ul>
                          </div>
                          <div className='col-md-4' style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <ul class=''>
                              <li class='ebook-meta-title'>
                                <span>Publisher</span>
                              </li>
                              <li class='ebook-meta-desc'>{dataDetail.publisher}</li>
                            </ul>
                          </div>
                          <div className='col-md-4' style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <ul class=''>
                              <li class='ebook-meta-title'>
                                <span>Pages</span>
                              </li>
                              <li class='ebook-meta-desc'>{dataDetail.total_page}</li>
                            </ul>
                          </div>
                          <div className='col-md-4 col-lg-4' style={{ marginTop: 10, textAlign: 'right' }}>
                            {dataDetail.downloadable === 1 && (
                              <a
                                href={dataDetail.ebook.media.url}
                                download
                                target='blank'
                                className='btn btn-md btn-color btn-button'
                                onClick={() => this.downloadEbook()}
                              >
                                Download
                              </a>
                            )}
                          </div>
                        </div>
                        <hr style={{ marginTop: '10px', marginBottom: '10px' }}></hr>
                      </div>
                    )}
                    {dataDetail.type === 'image' && datasTypeImage.length > 0 && (
                      <>
                        <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous}>
                          <CarouselIndicators items={datasTypeImage} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                          {slides}
                          <CarouselControl direction='prev' directionText='Previous' onClickHandler={this.previous} />
                          <CarouselControl direction='next' directionText='Next' onClickHandler={this.next} />
                        </Carousel>
                        <p
                          style={{
                            fontSize: 12,
                            lineHeight: '110%'
                          }}
                        >
                          {datasTypeImage[activeIndex].caption}
                        </p>
                      </>
                    )}
                    {/* {dataDetail.type === 'video' && (
	                                        <Player>
	                                            <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
	                                        </Player>
                                        )} */}
                  </div>
                  <div className='entry__article-wrap'>
                    <div className='entry__share'>
                      <div className='sticky-col'>
                        <div className='socials socials--rounded socials--large'>
                          <div
                            style={{
                              marginBottom: 10,
                              marginRight: window.outerWidth < 480 ? 10 : 0
                            }}
                          >
                            <FacebookShareButton
                              url={`${baseUrlFront}/${dataDetail.url}?tag_from=${dataDetail.tagables[0] ? dataDetail.tagables[0].tag.slug : 'unknown'}`}
                              quote={dataDetail.title}
                            >
                              <FacebookIcon size={38} round />
                            </FacebookShareButton>
                          </div>
                          <div
                            style={{
                              marginBottom: 10,
                              marginRight: window.outerWidth < 480 ? 10 : 0
                            }}
                          >
                            <TwitterShareButton
                              url={`${baseUrlFront}/${dataDetail.url}?tag_from=${dataDetail.tagables[0] ? dataDetail.tagables[0].tag.slug : 'unknown'}`}
                              title={dataDetail.title}
                            >
                              <TwitterIcon size={38} round />
                            </TwitterShareButton>
                          </div>
                          <div
                            style={{
                              marginBottom: 10,
                              marginRight: window.outerWidth < 480 ? 10 : 0
                            }}
                          >
                            <LinkedinShareButton
                              url={`${baseUrlFront}/${dataDetail.url}?tag_from=${dataDetail.tagables[0] ? dataDetail.tagables[0].tag.slug : 'unknown'}`}
                              windowWidth={750}
                              windowHeight={600}
                              className='Demo__some-network__share-button'
                            >
                              <LinkedinIcon size={38} round />
                            </LinkedinShareButton>
                          </div>
                          <div
                            style={{
                              marginBottom: 10,
                              marginRight: window.outerWidth < 480 ? 10 : 0
                            }}
                          >
                            <LineShareButton
                              url={`${baseUrlFront}/${dataDetail.url}?tag_from=${dataDetail.tagables[0] ? dataDetail.tagables[0].tag.slug : 'unknown'}`}
                              title={dataDetail.title}
                              className='Demo__some-network__share-button'
                            >
                              <LineIcon size={38} round />
                            </LineShareButton>
                          </div>
                          <div
                            style={{
                              marginBottom: 10,
                              marginRight: window.outerWidth < 480 ? 10 : 0
                            }}
                          >
                            <WhatsappShareButton
                              url={`${baseUrlFront}/${dataDetail.url}?tag_from=${dataDetail.tagables[0] ? dataDetail.tagables[0].tag.slug : 'unknown'}`}
                              title={dataDetail.title}
                              separator='::'
                            >
                              <WhatsappIcon size={38} round />
                            </WhatsappShareButton>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id='div-gpt-ad-1554349248915-0'></div>
                    {/* <GoogleAd slot='7885722082' googleAdId='ca-pub-9417606104654066' layout='in-article' format='fluid' /> */}

                    <div className='entry__article' style={{ minHeight: 200 }}>
                      {dataDetail.subtitle && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: dataDetail.subtitle
                          }}
                        />
                      )}
                      <p dangerouslySetInnerHTML={{ __html: dataDetail.content }}></p>

                      <div className='entry__tags'>
                        <i className='ui-tags'></i>
                        <span className='entry__tags-label'>Tags:</span>
                        {this.renderTagables()}
                      </div>
                      <div id='div-gpt-ad-1554349356263-0'></div>
                    </div>
                  </div>
                  <div style={{ marginTop: 10, marginBottom: 16 }}>
                    <div style={{ flexDirection: 'row' }}>
                      <button
                        type='button'
                        className='btn btn-lg btn-color btn-button'
                        onClick={() =>
                          // this.submitLike(dataDetail.like ? 'dislike' : 'like')
                          this.submitLike('like')
                        }
                      >
                        {/* Click for {dataDetail.like ? 'Unlike' : 'Like'} ( */}
                        Click for Like ({dataDetail.total_like})
                      </button>
                      <img
                        style={{
                          width: 48,
                          height: 48,
                          marginTop: 0,
                          marginLeft: 8
                        }}
                        src={dataDetail.like ? '/assets/img/content/thumbs-down-512.png' : '/assets/img/content/thumb-up-512.png'}
                      />
                    </div>
                    <p style={{ marginTop: 10, marginBottom: 10 }}>{dataDetail.like ? <u>You have already given likes for this post.</u> : null}</p>
                  </div>
                  <div className='newsletter-wide'>
                    <div className='widget widget_mc4wp_form_widget'>
                      <div className='newsletter-wide__container'>
                        <div className='newsletter-wide__text-holder'>
                          <p className='newsletter-wide__text'>
                            <i className='ui-email newsletter__icon'></i>
                            Subscribe for our daily news
                          </p>
                        </div>
                        <div className='newsletter-wide__form'>
                          <form className='mc4wp-form' name='formSubscribe' onSubmit={this.onSubmitSubscribe}>
                            <div className='mc4wp-form-fields'>
                              <div className='form-group'>
                                <input type='email' name='subscribeEmail' placeholder='Your email' value={subscribeEmail} onChange={this.onChange} />
                              </div>
                              <div className='form-group'>
                                <input type='submit' className='btn btn-lg btn-color' value='Sign Up' />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {submittedSubscribe && !subscribeEmail && (
                    <div
                      className='help-block'
                      style={{
                        backgroundColor: 'red',
                        padding: 10,
                        color: 'white'
                      }}
                    >
                      Email is required
                    </div>
                  )}
                  {!loadingDetail && (
                    <nav className='entry-navigation'>
                      <div className='clearfix'>
                        <div className='entry-navigation--left'>
                          <i className='ui-arrow-left'></i>
                          <span className='entry-navigation__label'>Previous Post</span>
                          {dataDetail.prev_post !== null ? (
                            <div className='entry-navigation__link'>
                              <Link
                                to={{
                                  pathname: `/${dataDetail.prev_post.url}`,
                                  search: `?tag_from=${searchParams.get('tag_from')}`
                                }}
                                rel='next'
                              >
                                {dataDetail.prev_post.title}
                              </Link>
                            </div>
                          ) : (
                            <p>No previous post data.</p>
                          )}
                        </div>
                        <div className='entry-navigation--right'>
                          <span className='entry-navigation__label'>Next Post</span>
                          <i className='ui-arrow-right'></i>
                          {dataDetail.next_post !== null ? (
                            <div className='entry-navigation__link'>
                              <Link
                                to={{
                                  pathname: `/${dataDetail.next_post.url}`,
                                  search: `?tag_from=${searchParams.get('tag_from')}`
                                }}
                                rel='prev'
                              >
                                {dataDetail.next_post.title}
                              </Link>
                            </div>
                          ) : (
                            <p>No next post data.</p>
                          )}
                        </div>
                      </div>
                    </nav>
                  )}
                  <div className='entry-author clearfix'>
                    <img
                      alt=''
                      src={dataDetailUser.mediaable ? dataDetailUser.mediaable.media.url : '/assets/img/person-icon.png'}
                      className='avatar lazyload'
                      style={{ width: 100, height: 100 }}
                    />
                    <div className='entry-author__info'>
                      <h6 className='entry-author__name'>
                        <Link
                          to={{
                            pathname: `/authorPost/${dataDetailUser.username}`
                          }}
                        >
                          {dataDetailUser.name}
                        </Link>
                      </h6>
                      <p className='mb-0'>{dataDetailUser.biografi}</p>
                    </div>
                  </div>

                  {!loadingRelated && (
                    <section className='section related-posts mt-40 mb-0'>
                      <div className='title-wrap title-wrap--line title-wrap--pr'>
                        <h3 className='section-title'>Related Articles</h3>
                      </div>

                      {datasRelated.length > 2 ? (
                        <div id='owl-posts-3-items' className='owl-carousel owl-theme owl-carousel--arrows-outside'>
                          {datasRelated.map((dataRelated, dataRelatedIndex) => (
                            <article className='entry thumb thumb--size-1' key={dataRelatedIndex}>
                              <div
                                className='entry__img-holder thumb__img-holder'
                                style={{
                                  backgroundImage:
                                    dataRelated.post !== null ? (dataRelated.post.mediaable ? `url(${dataRelated.post.mediaable.media.url}` : '') : ''
                                }}
                              >
                                <div className='bottom-gradient'>
                                  <div className='thumb-text-holder'>
                                    <Link
                                      to={{
                                        pathname: `/${dataRelated.post ? dataRelated.post.url : 'unknown'}`,
                                        search: `?tag_from=${
                                          dataRelated.post ? (dataRelated.post.tagables[0] ? dataRelated.post.tagables[0].tag.slug : 'unknown') : 'unknown'
                                        }`
                                      }}
                                    >
                                      <h2
                                        className='thumb-entry-title'
                                        dangerouslySetInnerHTML={{
                                          __html: dataRelated.post ? dataRelated.post.title : null
                                        }}
                                      ></h2>
                                    </Link>
                                  </div>
                                  <Link
                                    to={{
                                      pathname: `/${dataRelated.post ? dataRelated.post.url : 'unknown'}`,
                                      search: `?tag_from=${
                                        dataRelated.post ? (dataRelated.post.tagables[0] ? dataRelated.post.tagables[0].tag.slug : 'unknown') : 'unknown'
                                      }`
                                    }}
                                    className='thumb-url'
                                  />
                                </div>
                              </div>
                            </article>
                          ))}
                        </div>
                      ) : (
                        <div>No related articles data.</div>
                      )}
                    </section>
                  )}
                </article>

                {dataDetail.commentable === 1 ? (
                  <LoadingOverlay active={loadingComments} spinner text='Loading your content...'>
                    <div className='entry-comments'>
                      <div className='title-wrap title-wrap--line'>
                        <h3 className='section-title'>{datasComments.length} comments</h3>
                      </div>
                      <ul className='comment-list'>
                        {datasComments.length > 0
                          ? datasComments.map((dataComments, dataCommentsIndex) => (
                              <li className='comment' key={dataCommentsIndex} style={{ minHeight: 200 }}>
                                <div className='comment-body'>
                                  <div className='comment-avatar'>
                                    <img
                                      alt=''
                                      src={
                                        dataComments.user
                                          ? dataComments.user.media
                                            ? dataComments.user.media.url
                                            : '/assets/img/person-icon.png'
                                          : '/assets/img/person-icon.png'
                                      }
                                      style={{ width: 100, height: 100 }}
                                    />
                                  </div>
                                  <div className='comment-text'>
                                    <h6 className='comment-author'>{dataComments.user.name}</h6>
                                    <div className='comment-metadata'>
                                      <a href='#' className='comment-date'>
                                        {moment(dataComments.createdAt).format('LLLL')}
                                      </a>
                                    </div>
                                    <p>{dataComments.content}</p>
                                  </div>
                                </div>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </LoadingOverlay>
                ) : null}

                {dataDetail.commentable === 1 ? (
                  <div id='respond' className='comment-respond'>
                    <div className='title-wrap'>
                      <h5 className='comment-respond__title section-title'>Leave a Reply</h5>
                    </div>
                    <form id='form' className='comment-form' name='form' onSubmit={this.onSubmitComment}>
                      <p className='comment-form-comment' style={{ marginBottom: 10 }}>
                        <label htmlFor='comment'>Comment</label>
                        <textarea id='comment' name='comment' rows='5' value={comment} onChange={this.onChange} style={{ marginBottom: 0 }}></textarea>
                        {submittedComment && !comment && (
                          <div
                            className='help-block'
                            style={{
                              backgroundColor: 'red',
                              paddingLeft: 10,
                              paddingRight: 10
                            }}
                          >
                            <small style={{ color: 'white' }}>Comment is required</small>
                          </div>
                        )}
                      </p>
                      <div
                        style={{
                          marginBottom: 16,
                          transform: 'scale(0.9)',
                          transformOrigin: '0 0'
                        }}
                      >
                        <Reaptcha sitekey={sitekeyReaptcha} onVerify={this.onVerify} />
                      </div>
                      <p className='comment-form-submit'>
                        <input
                          type='submit'
                          className='btn btn-md btn-color btn-button mt-5'
                          value={`${loadingCreateComments ? 'Loading...' : 'Post Comment'}`}
                          disabled={!this.state.verified || loadingCreateComments}
                          id='submit-message'
                        />
                      </p>
                    </form>
                  </div>
                ) : null}
              </div>
            </div>
            <aside className='col-lg-4 sidebar sidebar--right'>
              <PopularPosts loading={loadingTopTen} data={datasTopTen} />
              <SocialMedia />
              {/* <div style={{ marginTop: 16 }}>
								<a target="_blank" href="">
									<img
										src="https://ivooxs3.s3.ap-southeast-1.amazonaws.com/media_public/1566290306843_upload_36d2468c950001049e98aab00d5fd920.png"
										style={{ width: '100%' }}
									/>
								</a>
							</div> */}
              {datasAdv != null && datasAdv['InNewsRight'] && (
                <div style={{ marginTop: 16 }}>
                  {datasAdv['InNewsRight'].type === 'media' && datasAdv['InNewsRight'].media && datasAdv['InNewsRight'].media.media_type === 'image' && (
                    <a target='_blank' href={datasAdv['InNewsRight'].url !== '' ? datasAdv['InNewsRight'].url : '#'}>
                      <img src={datasAdv['InNewsRight'] ? datasAdv['InNewsRight'].media.url : ''} style={{ width: '100%' }} />
                    </a>
                  )}
                  {datasAdv['InNewsRight'].type == 'media' && datasAdv['InNewsRight'].media && datasAdv['InNewsRight'].media.media_type === 'video' && (
                    <video width='100%' style={{ height: 105 }} controls={false} autoPlay loop>
                      <source src={datasAdv['InNewsRight'].media.url} type={datasAdv['InNewsRight'].media.file_type} />
                      Your browser does not support the video tag.
                    </video>
                  )}
                  {datasAdv['InNewsRight'].type == 'adsense' && datasAdv['InNewsRight'].adsense && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: datasAdv['InNewsRight'].adsense
                      }}
                    ></p>
                  )}
                </div>
              )}
            </aside>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(Articles);
